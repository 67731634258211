<template>
  <div class="permission-wrap">
<!--    <side-bar :treeData="list" />-->
    <div style="width: 100%;height: 100%;margin-left: 30px;">
<!--      <page-header></page-header>-->
      <div class="content">
        <div class="table-wrap" ref="tableWrap" style="border-bottom: none;">
          <div class="item">
            <span style="margin: 0 8%;padding: 10px;border-left: 1px solid #ccc;">权限修改</span>
          </div>
        </div>
        <vertical-table :dataList="lastData"/>
        <el-button plain size="medium" @click="cancelChange">取消</el-button>
        <el-button type="primary" size="medium" @click="savePermission">确定</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import SideBar from '@/components/SideBar'
import VerticalTable from '@/components/VerticalTable'
import { selectOneRole, updateRoleRelation } from '@/api/settings/permission'
import { selectOneRoleMenu, setDiyUserAuth } from '@/api/settings/userManagement.js'
export default {
  components: {
    SideBar,
    VerticalTable
  },
  data () {
    return ({
      selectedMenu: [],
      title: '修改角色权限',
      list: [{
        label: '系统设置',
        children: [
          {
            label: '用户管理',
            url: '/settings/userManagement'
          },
          {
            label: '权限设置',
            url: '/settings/permission'
          }
        ]
      }],
      lastData: [],
      changeMenuIds: []
    })
  },
  methods: {
    async showInfo () {
      const res = await selectOneRole({ id: this.$route.params.id })
      // console.log(res)
      this.transformData(res.data)
    },
    async viewSelectOneRoleMenu () {
      const res = await selectOneRoleMenu({ id: this.$route.params.id, isC: this.$route.params.c })
      // console.log(res)
      this.transformData(res.data)
    },
    savePermission () {
      var data = this.lastData
      for (var i = 0; i < data.length; i++) {
        if (data[i].parent === true) {
          this.changeMenuIds.push(data[i].id)
        }
        if (data[i].data.length > 0) {
          for (var j = 0; j < data[i].data.length; j++) {
            this.changeMenuIds.push(data[i].data[j])
          }
        }
      }
      console.log(this.changeMenuIds)
      console.log(this.$route.params.id)
      if (this.type === 'setRole') {
        this.viewSetDiyUserAuth()
      } else {
        this.updatePermission()
      }
    },
    async updatePermission () {
      const res = await updateRoleRelation({ roleid: this.$route.params.id, menuid: this.changeMenuIds })
      if (res.code === 0) {
        this.$message.success('角色权限修改成功！')
      } else {
        this.$message.error('角色权限修改失败！')
      }
      this.cancelChange()
    },
    async viewSetDiyUserAuth () {
      const res = await setDiyUserAuth({ userId: this.$route.params.id, menuIds: this.changeMenuIds, isC: this.$route.params.c })
      if (res.code === 0) {
        this.$message.success('用户权限修改成功！')
      } else {
        this.$message.error('用户权限修改失败！')
      }
      // 调整页面
      this.cancelChange()
    },
    transformData (data) {
      for (var i = 0; i < data.length; i++) {
        if (data[i].pid === '0') {
          data[i].children = []
          data[i].data = []
          this.lastData.push(data[i])
        }
      }
      for (var j = 0; j < data.length; j++) {
        if (data[j].pid !== '0') {
          data[j].children = []
          data[j].data = []
          var index = this.returnIdByPid(data[j].pid, this.lastData, data)
          if (index !== undefined) {
            if (data[j].id === '9') {
              data[j].name += '（全部项目）'
            }
            this.lastData[index].children.push(data[j])
          } else {
            var ppid = this.returnPid(data[j].pid, data)
            index = this.returnIdByPid(ppid, this.lastData, data)
            if (data[j].pid === '9') {
              data[j].name += '（全部）'
            }
            this.lastData[index].children.push(data[j])
          }
        }
      }
      console.log(this.lastData)
    },
    returnIdByPid (pid, data, mother) {
      for (var i = 0; i < data.length; i++) {
        if (data[i].id === pid) {
          return i
        }
      }
    },
    returnPid (pid, data) {
      for (var i = 0; i < data.length; i++) {
        if (data[i].id === pid) {
          return data[i].pid
        }
      }
    },
    cancelChange () {
      if (this.type === 'setRole') {
        if (this.$route.params.cid === 'undefined') {
          this.$router.push('/settings/userManagement')
        } else {
          this.$router.push('/settings/userManagement/editCusUser/' + this.$route.params.cid)
        }
      } else {
        this.$router.push('/settings/role')
      }
    }
  },
  mounted () {
    this.type = this.$route.meta.type
    var is_c = this.$route.params.c
    if (is_c === '0') {
      if (this.type === 'setRole') {
        this.viewSelectOneRoleMenu()
      } else {
        this.showInfo()
      }
    } else {
      this.viewSelectOneRoleMenu()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/index';
.permission-wrap {
  width: 100%;
  height: 100%;
  padding: 30px 30px;
  display: flex;
  .content {
    width: 100%;
    min-height: $contentHeight;
    // margin-left: 30px;
    border-radius: 8px;
    background: #fff;
    box-shadow: 5px 5px 10px rgba(228, 228, 228, .35);
    padding: 50px 20px 20px;
    text-align: center;
    .table-wrap {
      text-align: left;
    }
    .el-button {
      margin-top: 30px;
      margin-right: 20px;
    }
  }
}
</style>
