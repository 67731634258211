<template>
  <div class="table-wrap" ref="tableWrap">
    <div class="item" v-for="item in dataList" :key="item.id">
      <span class="title"><el-checkbox v-model="item.parent" style="margin-right: 10px" :checked="item.pitchOn === 1"></el-checkbox>{{item.name}}</span>
      <el-checkbox-group v-model="item.data" @change="selectChange">
        <el-checkbox v-for="child in item.children" :label="child.id" :key="child.id" :checked="child.pitchOn === 1">{{ child.name }}</el-checkbox>
      </el-checkbox-group>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {}
  },
  props: {
    dataList: {
      type: Array,
      required: true
    }
  },
  methods: {
    selectChange () {
      this.$forceUpdate()
    }
  }
  // mounted () {
  //   const itemList = this.$refs.tableWrap.querySelectorAll('.el-checkbox-group')
  //   itemList.forEach((item, i) => {
  //     console.log(item)
  //     console.log(i)
  //     // while (item.querySelector('.el-checkbox:nth-child(' + (i + 1) + ')') !== null) {
  //     //   console.log(123)
  //     //   console.log(item.querySelector('.el-checkbox:nth-child(8)'))
  //     // }
  //   })
  // }
}
</script>

<style lang="scss">
  .table-wrap {
    border: 1px solid #ccc;
    .item {
      display: flex;
      // border: 1px solid #ccc;
      &:last-child {
      .title {
        border-bottom: none !important;
      }
      .el-checkbox-group {
        border-bottom: none !important;
      }
    }
      .title {
        width: 8%;
        font-size: 14px ;
        // height: 40px;
        line-height: 40px;
        text-align: center;
        background: #F7F7F7;
        border-right: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
      }
      .el-checkbox-group {
        width: 92%;
        border-bottom: 1px solid #ccc;
        .el-checkbox {
          height: 40px;
          line-height: 40px;
          padding-left: 10px;
          padding-right: 5px;
          margin-right: 0px;
          // border-right: 1px solid #ccc;
        }
      }
    }
  }
</style>
