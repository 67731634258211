import request from '@/utils/request'
export function selectOneRole (data) {
  return request({
    url: '/sys/role/selectOneRole',
    method: 'POST',
    data
  })
}
export function updateRoleRelation (data) {
  return request({
    url: '/sys/role/updateRoleRelation',
    method: 'POST',
    data
  })
}
